<template>
  <div class="my-subscriptions">
    <div class="subscriptions-list" v-if="filterSubscriptions.length > 0">
      <div class="list-title">
        <h2>{{ $t('settings.subscriptions.title') }} ({{ filterSubscriptions.length }})</h2>
        <p>{{ $t('settings.subscriptions.premium') }}</p>
      </div>

      <div class="subscriptions">
        <div class="subscription" v-for="(subscription, i) in filterSubscriptions" :key="subscription.type">
          <div class="subscription-bloc">
            <div class="subscription-type">
              <img :src="require(`@/assets/svg/v2/categories/${subscription.path}`)" :alt="subscription.label" width="44" height="44" />
              <div class="subscritpion-type-infos">
                <div class="category-infos">
                  <h4>{{ subscription.label }}</h4>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    :content="subscriptionSubCategories(subscription)"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" />
                  </el-tooltip>
                </div>
                <p>{{ $t('settings.subscriptions.sub_at') }} {{ subscribedSince(subscription) }}</p>
              </div>
            </div>
            <div class="subscription-price-infos">
              <div class="price">
                <p class="amount">{{ subscription.price }} EUR</p>
                <p class="vat">{{ $t('subscriptions.all_taxs') }} /{{ subscriptionPeriod(subscription) }}</p>
              </div>
              <p v-if="subscription.next_payment">{{ $t('settings.subscriptions.next_payment') }} {{ nextPaymentDate(subscription) }}</p>
              <p v-else>{{ $t('settings.subscriptions.subscription_term') }} {{ commitment(subscription) }}</p>
              <p>{{ $t('settings.subscriptions.commitment') }} {{ commitment(subscription) }}</p>
            </div>
          </div>
          <el-divider class="subscription-divider" v-if="i < filterSubscriptions.length - 1"/>
        </div>
      </div>
    </div>
    <div class="subscriptions-explore">
      <div class="subscriptions-icons-list">
        <el-tooltip
          class="tooltip"
          effect="dark"
          :content="subscription.label"
          placement="top"
          v-for="subscription in filterNoSubscriptions" :key="subscription.type"
        >
          <img @click="$router.push('/subscriptions')" :src="require(`@/assets/svg/v2/categories/${subscription.path}`)" :alt="subscription.label" width="44" height="44" />
        </el-tooltip>
      </div>
      <div class="explore" @click="$router.push('/subscriptions')">
        <h3>{{ $t('settings.subscriptions.explore') }}</h3>
        <img src="@/assets/svg/v2/arrowToRight.svg" alt="Subscription explore" />
      </div>
      <p class="list">{{ spiritTypeList }}</p>
    </div>
    
    <cancelSubscription v-if="cancelDialog" :subscription="selectedCancelSubscription" @close="cancelDialog = false" />
    <continueSubscriptionVue v-if="reactiveDialog" :subscription="selectedReactiveSubscription" @close="reactiveDialog = false" />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filter } from 'lodash';
import m from 'moment';
import cancelSubscription from '@/components/modules/dialogs/cancelSubscription.vue';
import continueSubscriptionVue from '@/components/modules/dialogs/continueSubscription.vue';

export default {
  components: {
    cancelSubscription,
    continueSubscriptionVue
  },
  data() {
    return {
      cancelDialog: false,
      reactiveDialog: false,
      selectedCancelSubscription: null,
      selectedReactiveSubscription: null
    };
  },
  computed: {
    ...mapGetters({
      subscriptions: 'getSubscriptions',
      subscriptionsInformations: 'getSubscriptionsInformations'
    }),
    filterSubscriptions() {
      return filter(this.subscriptions, (o) => {
        if (o.subscription_term) {
          return m().format('YYYY-MM-DD HH:mm:ss') < m(o.subscription_term, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        }
      });
    },
    filterNoSubscriptions() {
      return filter(this.subscriptions, (o) => {
        return o.next_payment === null;
      });
    },
    lastDatesubscription() {
      const activesubscription = filter(this.subscriptions, { subscription_state: 1 });

      if (activesubscription.length > 0) {
        return true;
      }

      return false;
    },
    spiritTypeList() {
      let spiritsLabels = '';
  
      this.subscriptions.forEach((o, i) => {
        if (o.subscription_state === 0) {
          spiritsLabels+= o.label;

          if (i < this.subscriptions.length -1) {
            spiritsLabels+= ' • ';
          }
        }
      });
  
      return spiritsLabels;
    },
    subscribedSinceAllSubscriptions() {
      let minDate = m().format('YYYY-MM-DD HH:mm:ss');
  
      this.subscriptions.forEach((subscription) => {
        if (m(minDate, 'YYYY-MM-DD HH:mm:ss').isAfter(subscription.created_at, 'YYYY-MM-DD HHmm:ss')) {
          minDate = subscription.created_at;
        }
      });
      let transform = m(minDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
      return transform;
    },
  },
  methods: {
    ...mapActions({
      getSubscriptions: 'getSubscriptions'
    }),
    subscriptionPeriod(subscription) {
      if (subscription.subscription_period_type === 0) {
        return this.$t('settings.subscriptions.monthly');
      }
      return this.$t('settings.subscriptions.yearly');
    },
    nextPaymentDate(subscription) {
      return m(subscription.next_payment, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    nextSubscriptionTerm(subscription) {
      return m(subscription.subscription_term, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    subscribedSince(subscription) {
      return m(subscription.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    commitment(subscription) {
      return m(subscription.commitment, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    openCancelSubscription(subscription) {
      this.selectedCancelSubscription = subscription;
      this.cancelDialog = true;
    },
    openReactiveSubscription(subscription) {
      this.selectedReactiveSubscription = subscription;
      this.reactiveDialog = true;
    },
    subscriptionSubCategories(subscription) {
      let text = '';

      subscription.details.forEach((subCategory, i) => {
        text += subCategory;
        if (i < subscription.details.length - 1) {
          text += ', ';
        }
      });

      return text;
    }
  },
  mounted() {
    this.getSubscriptions();
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.subscriptions-dashboard, .historical-sub-date, .list-title, .subscription, .subscription-type, .category-infos, .subscription-price-infos,
.price, .subscriptions-explore, .explore, .last-month-price, .subscription-bloc {
  display: flex;
}

.my-subscriptions {
  margin-left: 53px;
  width: 100%;
  max-width: 750px;
  min-width: 600px;

  .subscriptions-dashboard, .subscriptions-list {
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid $border;
    background: #333;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;

    .list-title {
      justify-content: space-between;
      margin-bottom: 45px;

      h2 {
        color: $subtitle;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        font-family: Hyperwave;
        font-size: 22px;
        font-weight: 700;
        color: white;
      }
    }

    .last-invoice {

      .last-month-price {
        margin-top: 12px;
        
        p {
          color: white;
          font-size: 24px;
          font-weight: 600;
        }
        
        span {
          color: white;
          font-size: 12px;
          font-weight: 700;
          margin-left: 5px;
        }
      }
      
      h3 {
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
      }
    }
    
    .historical-sub-date {
      margin-left: 17px;

      img {
        margin-right: 8px;
      }

      p {
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .subscriptions-dashboard {
    justify-content: flex-start;

    .last-invoice {
      width: 49%;
    }
  }
}

.subscription {
  flex-direction: column;
  
  .subscription-bloc {
    justify-content: space-between;

    .subscription-type {
      width: 45%;
  
      img {
        margin-right: 16px;
      }
  
      .subscritpion-type-infos {
  
        .category-infos {
          margin-bottom: 3px;
  
          h4 {
            color: white;
            font-size: 16px;
            font-weight: 600;
          }
  
          img {
            margin-left: 5px;
          }
        }
  
        p {
          color: $subtitle;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  
    .subscription-price-infos {
      align-items: flex-end;
      flex-direction: column;
  
      .price {
        font-size: 16px;
        font-weight: 500;
  
        .amount {
          color: white;
          margin-top: 0;
        }
  
        .vat {
          color: white;
          margin-left: 4px;
          margin-top: 0;
        }
      }
  
      p {
        margin-top: 4px;
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
      }
  
      button {
        border-radius: 8px;
        border: 1px solid $border;
        background: #333;
        padding: 12px;
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
        margin-top: 9px;
  
        &:hover {
          background: #b31a1a5d;
          transition: .5s;
        }
      }
  
      .reactive-btn:hover {
        background: #24d22c4f;
      }
    }
  }

  .subscription-divider {
    margin: 18px 0;
    border-color: $grey40;
  }
}

.subscriptions-explore {
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $border;
  background: #222;
  padding: 20px;
  width: 100%;

  .subscriptions-icons-list {
    margin-bottom: 8px;

    img {
      cursor: pointer;
    }
  }

  .explore {
    cursor: pointer;
    margin-bottom: 9px;

    h3 {
      color: white;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .list {
    color: $grey40;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
}

.vertical-divider-payment {
  height: 58px;
  border-color: $grey40;
}

@media screen and (max-width: 500px) {
  .my-subscriptions {
    min-width: 320px;
    margin-left: 0;
    margin-bottom: 20px;

    .subscriptions-list {
      width: auto;
      padding: 20px 10px;
    }

    .subscriptions-explore {
      width: auto;
      padding: 20px 10px;
    }
  }

  .subscription {
    
    .subscription-bloc {
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
      width: 100%;
  
      .subscription-type {
        width: 100%;
        
        .subscritpion-type-infos {
          width: 200px;
  
          p {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>