<template>
  <div v-if="updateType" class="update-contact">
    <div class="update-contact-header">
      <h2 v-if="updateType === 'email'">{{ $t('settings.update_dialog.title_email') }}</h2>
      <h2 v-else>{{ $t('settings.update_dialog.title_phone') }}</h2>
      <button @click="$emit('close')">
        <img src="@/assets/svg/v2/close.svg" alt="Close" />
      </button>
    </div>
    <div class="update-contact-body">
      <div class="actual-value">
        <h3 v-if="updateType === 'email'">{{ $t('settings.update_dialog.actual_email') }}</h3>
        <h3 v-else>{{ $t('settings.update_dialog.actual_phone') }}</h3>

        <el-input type="text" minlength="2" maxlength="75" v-model="actualValue" :size="size" disabled />
      </div>
      <div class="new-value">
        <h3 v-if="updateType === 'email'">{{ $t('settings.update_dialog.new_email') }}</h3>
        <h3 v-else>{{ $t('settings.update_dialog.new_phone') }}</h3>

        <el-input v-if="updateType === 'email'" placeholder="new@email.com" type="text" minlength="2" maxlength="75" v-model="newValue" :size="size" />
        <el-input v-else v-model="newValue" placeholder="0611223344" :size="size" autocomplete="phone" clearable>
          <template #prepend>
            <el-select :placeholder="$t('register.step_1.select')" filterable v-model="phonecountryCode" :size="size" clearable effect="dark">
              <el-option class="phone-country-options" v-for="item in country" :key="item.nom_en_gb"
                         :label="item.nom_en_gb + '(+' + item.phone_code + ')'" :value="item.phone_code" effect="dark">
                <span>
                  {{ item.nom_en_gb + '(+' + item.phone_code + ')' }}
                </span>
              </el-option>
            </el-select>
          </template>
        </el-input>
      </div>
    </div>
    <button @click="checkValues()" class="generic-btn register-update">{{ $t('settings.update_dialog.register') }}</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ElMessage } from 'element-plus';

export default {
  props: {
    updateType: {
      type: String,
      required: false,
      default: null
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actualValue: null,
      newValue: null,
      phonecountryCode: null,

      size: 'large'
    };
  },
  watch: {
    updateType() {
      if (this.form && this.updateType === 'email') {
        this.actualValue = this.form.address.email;
      } else {
        this.actualValue = `(+${this.form.address.phone_country_code}) ${this.form.address.phone}`;
      }
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists'
    }),
    country() {
      return this.getLists?.country;
    }
  },
  methods: {
    ...mapActions({
      getEmailPhoneCode: 'getEmailPhoneCode'
    }),
    async sendCode() {
      const payload = {
        type: 0,
        new_value: this.newValue
      };

      if (this.updateType === 'phone') {
        payload.type = 1;
        payload.phone_country_code = this.phonecountryCode;
      }

      await this.getEmailPhoneCode(payload).then(() => {
        const values = {
          type: payload.type,
          newValue: this.newValue
        };

        this.$emit('values', values);
        this.$emit('close');
      });
    },
    checkValues() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneRegex = /^[0-9]{8,13}$/;
      
      if (this.newValue === this.actualValue || this.newValue === this.form.address.phone) {
        ElMessage({
          message: this.$t(`settings.update_dialog.err_${this.updateType}_2`),
          type: 'warning',
          grouping: true
        });
      } else if ((this.updateType === 'email' && emailRegex.test(this.newValue)) || (this.updateType === 'phone' && phoneRegex.test(this.newValue))) {
        this.sendCode();
      } else {
        ElMessage({
          message: this.$t(`settings.update_dialog.err_${this.updateType}`),
          type: 'warning',
          grouping: true
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/auth.scss';

.update-contact {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    height: 320px;
    width: 600px;
    border-radius: 20px;
    border-radius: 20px;
    border: 1px solid $border;
    background: #444;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    z-index: 5000;

    .update-contact-header {
        border-bottom: 1px solid $border;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding-bottom: 15px;

        h2 {
            color: white;
            font-size: 18px;
            font-weight: 600;
            margin-left: 22px;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 0;
            border-radius: 76px;
            border: 1px solid $border;
            background: #444;
            height: 38px;
            width: 38px;
            margin-right: 22px;
        }
    }

    .update-contact-body {
        margin: 15px 28px;

        .actual-value, .new-value {

            h3 {
                color: white;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 8px;
            }
        }

        .actual-value {
            margin-bottom: 24px;
        }
    }

    .register-update {
        width: 125px;
        height: 47px;
        margin: auto;
    }
}

.el-input-group__prepend, .el-input-group__prepend {
    box-shadow: none;
    background: none;
    padding: 0;
    width: 40%;
}

@media screen and (max-height: 850px) {
  .update-contact {
    top: 425px;
  }
}
</style>