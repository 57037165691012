<template>
  <div class="confirm-dialog">
    <div class="confirm-dialog-content">
      <div class="confirm-dialog-content-header">
        <h2 v-if="values.type === 0">
          {{ $t('settings.confirm.subtitle_email') }}
        </h2>
        <h2 v-else>{{ $t('settings.confirm.subtitle_phone') }}</h2>
        <button @click="$emit('close')">
          <img src="@/assets/svg/v2/close.svg" alt="Close" />
        </button>
      </div>

      <el-divider class="confirm-dialog-divider" />

      <form>
        <div class="form-input-bloc">
          <h3 v-if="values.type === 0">
            {{ $t('settings.confirm.new_email') }}
          </h3>
          <h3 v-else>{{ $t('settings.confirm.new_phone') }}</h3>

          <el-input
            type="text"
            minlength="2"
            maxlength="75"
            :placeholder="values.newValue"
            :size="size"
            disabled
          />
        </div>

        <div class="form-input-bloc">
          <h3 v-if="values.type === 0">
            {{ $t('settings.confirm.code_email') }}
          </h3>
          <h3 v-else>{{ $t('settings.confirm.code_phone') }}</h3>

          <el-input
            type="text"
            minlength="6"
            maxlength="6"
            v-model="code"
            :size="size"
          />
        </div>

        <button
          @click.prevent="updateContactInformations()"
          class="generic-btn"
        >
          {{ $t('commons.continue') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      size: 'large',

      code: null,
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
  },
  methods: {
    ...mapActions({
      getEmailPhoneCode: 'getEmailPhoneCode',
      confirmNewValue: 'confirmNewValue',
    }),
    async updateContactInformations() {
      const payloadEmail = {
        email_code: this.code,
        email: this.values.newValue,
      }

      const payloadPhone = {
        phone_code: this.code,
        phone: this.values.newValue,
      }

      if (this.values.type === 1) {
        await this.confirmNewValue(payloadPhone).then(() => {
          this.$emit('close')
        })
      } else {
        await this.confirmNewValue(payloadEmail).then(() => {
          this.$emit('close')
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.confirm-dialog {
  z-index: 5000;
  position: relative;

  .confirm-dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $border;
    background: #444;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    height: 330px;
    width: 600px;
    border-radius: 20px;

    .confirm-dialog-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      
      h2 {
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-left: 22px;
      }
      
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: 0;
        border-radius: 76px;
        border: 1px solid $border;
        background: #444;
        height: 38px;
        width: 38px;
        min-width: 38px;
        margin-right: 22px;
      }
    }

    .confirm-dialog-divider {
      border-color: $border;
      margin: 15px auto;
    }

    form {
      display: flex;
      flex-direction: column;
      margin: 0 23px;

      .form-input-bloc {
        margin-bottom: 24px;

        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }

      button {
        width: 125px;
        height: 47px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-height: 850px) {
  .confirm-dialog-content {
    width: 98%!important;
  }
}
</style>
