<template>
    <div class="settings-preferences">
        <div class="settings-preferences-header">
            <h1>{{ $t('settings.preferences.title') }}</h1>
        </div>

        <div class="settings-preferences-body">
            <div class="settings-preferences-card">
                <h2>Trading</h2>

                <div>
                    <el-checkbox @change="preferences()" v-model="getUserDetails.preferences.mails.trading.create_order_buy" :true-value="1" :false-value="0" :label="$t('settings.preference_t_1')" />
                    <el-checkbox @change="preferences()" v-model="getUserDetails.preferences.mails.trading.order_await_validation" :true-value="1" :false-value="0" :label="$t('settings.preference_t_6')" />
                    <el-checkbox @change="preferences()" disabled v-model="getUserDetails.preferences.mails.trading.create_order_sell" :true-value="1" :false-value="0" :label="$t('settings.preference_t_2')" />
                    <el-checkbox @change="preferences()" disabled v-model="getUserDetails.preferences.mails.trading.create_order_swap" :true-value="1" :false-value="0" :label="$t('settings.preference_t_3')" />
                    <el-checkbox @change="preferences()" disabled v-model="getUserDetails.preferences.mails.trading.create_order_blocktrade" :true-value="1" :false-value="0" :label="$t('settings.preference_t_4')" />
                </div>
            </div>

            <div class="settings-preferences-card">
                <h2>Matchs</h2>

                <div>
                    <el-checkbox @change="preferences()" disabled v-model="getUserDetails.preferences.mails.matchs.refuse_by_buyer_to_seller" :true-value="1" :false-value="0" :label="$t('settings.preference_t_5')" />
                </div>
            </div>

            <div class="settings-preferences-card">
                <h2>Newsletter</h2>

                <div>
                    <el-checkbox @change="preferences()" v-model="getUserDetails.preferences.mails.newsletter.newsletter" :true-value="1" :false-value="0" :label="$t('settings.newsletter')" />
                </div>
            </div>

            <div v-if="getUserDetails.address.country === 'US'" class="settings-preferences-card">
                <h2>SMS</h2>

                <div>
                    <el-checkbox @change="preferences()" v-model="getUserDetails.preferences.sms.us_preference" :true-value="1" :false-value="0" :label="$t('settings.preference_t_7')" />
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        getLists: 'getLists',
        getUserDetails: 'getUserDetails',
      }),
    },
    methods: {
      ...mapActions({
        changePreferences: 'changePreferences',
      }),
      preferences() {
            this.changePreferences(this.getUserDetails.preferences);
        },
    },
  }
</script>
  
<style lang="scss">
@import '@/assets/styles/root.scss';

.settings-preferences {
  margin-left: 53px !important;
  width: 100%;
  max-width: 750px;

  .settings-preferences-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      padding: 0;
    }
  }

  .settings-preferences-body {

    .settings-preferences-card {
        border: 1px solid $border;
        border-radius: 12px;
        background: #333;
        padding: 20px;
        margin-bottom: 20px;

        h2 {
            margin-bottom: 10px;
        }
    }
  }
}

@media screen and (max-width: 500px) {
    .settings-preferences {
        margin-left: 0px !important;
    }
}
</style>
  