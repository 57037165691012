<template>
  <div class="payment-method-history">
    <div class="payment-method-header">
      <h1>{{ $t('settings.payment_method') }}</h1>
    </div>

    <div class="cards" v-if="getUserDetails">
      <div class="card" v-for="(card, i) in getUserDetails.cards" :key="i">
        <div class="card-header">
          <div class="card-type">
            <img v-if="getCardType(card.alias) === 'visa'" src="@/assets/svg/v2/subscriptions/visa.svg" alt="Visa" />
            <img v-else-if="getCardType(card.alias) === 'mastercard'" src="@/assets/svg/v2/subscriptions/mastercard.svg" alt="Mastercard" />
            <img v-else-if="getCardType(card.alias) === 'amex'" src="@/assets/svg/v2/subscriptions/ae.svg" alt="AE" />

             <p>{{ card.provider }}</p>
          </div>
          <div class="card-status">
            <p>{{ $t('commons.active') }}</p>
          </div>
        </div>

        <div class="card-infos">
          <p>{{ $t('settings.card_numbers') }} {{ card.alias }}</p>
          <p>{{ $t('settings.card_expiration') }} {{ cardExpiration(card.exp) }}</p>

          <div>
            <p>{{ $t('settings.card_created_at') }} {{ toDate(card.created_at) }}</p>
            <!-- <button @click="openDialogUpdateCards(card)">{{ $t('settings.card_update') }}</button> -->
          </div>
        </div>
      </div>
    </div>

    <p v-if="getUserDetails.cards.length === 0" class="empty-card">{{ $t('settings.card_empty') }}</p>
  </div>

  <CommonModal
      v-if="dialogUpdateCard"
      size="small"
      :modal-title="$t('settings.card_update_title')"
      @close="dialogUpdateCard = false"
    >
    <updateCard :currentCard="currentCard" @close="dialogUpdateCard = false" />
  </CommonModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import CommonModal from '@/components/commons/modal.vue';
import updateCard from '@/components/modules/payments/card';

export default {
  components: {
    CommonModal,
    updateCard
  },
  data() {
    return {
      dialogUpdateCard: false,
      currentCard: null,
      cardType: [
          { type: 'visa', prfx: ['4'], fees: 2 },
          { type: 'mastercard', prfx: ['51', '52', '53', '54', '55'], fees: 2 },
          { type: 'amex', prfx: ['34', '37'], fees: 3 }
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
    }),
  },
  methods: {
    ...mapActions({}),
    getCardType(card) {
      const carteNumbers = card.replace(/\D/g, '');
      let type = 'visa';

      for (let i = 0; i < this.cardType.length; i++) {
          for (const prf of this.cardType[i].prfx) {
              if (carteNumbers.startsWith(prf)) {
                  if (this.cardType[i].type === 'visa') {
                    type = 'visa';
                  } else if (this.cardType[i].type === 'mastercard') {
                    type = 'mastercard';
                  } else if (this.cardType[i].type === 'amex') {
                    type = 'amex';
                  }
              }
          }
      }
      return type;
    },
    toDate(date) {
      return m(date, 'X').format('DD/MM/YYYY');
    },
    cardExpiration(date) {
      return m(date, 'MMYY').format('MM/YYYY');
    },
    openDialogUpdateCards(card) {
      this.currentCard = card;
      this.dialogUpdateCard = true;
    }
  }
};
</script>
    
<style lang="scss">
@import '@/assets/styles/root.scss';

.payment-method-header, .card-header, .card-type, .card-infos div {
  display: flex;
}
  
.payment-method-history {
  margin-left: 53px!important;
  width: 100%;
  max-width: 750px;
  min-width: 600px;

  .payment-method-header {
    justify-content: space-between;
    margin-bottom: 18px;

    h1 {
        font-size: 24px;
        font-weight: 600;
    }

    button {
      cursor: not-allowed;
      border-radius: 8px;
      border: 1px solid $border;
      background: #333;
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
      height: 36px;
      width: 143px;
    }
  }
}

.card {
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .card-header {
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    .card-type {
      align-items: center;

      p {
        margin-left: 12px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .card-status p {
      color: #84CC16;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .card-infos {
    margin-left: 20px;

    p {
      font-size: 15px;
    }

    div {
      justify-content: space-between;
      color: $grey40;
      margin-top: 5px;

      p {
        font-size: 14px;
      }

      button {
        margin-right: 20px;
        background: transparent;
        border: 0;
      }
    }
  }
}

.empty-card {
  margin-bottom: 10px;
}

@media screen and (max-width: 720px) {
  .payment-method-history {
    max-width: 500px;
    min-width: 280px;
    margin: auto!important;
  }
}
</style>