<template>
  <div class="cancel-subscription">
    <div class="cancel-dialog-content">
      <div class="cancel-dialog-content-header">
        <h1>{{ $t('settings.subscriptions.cancel_title') }}</h1>
        <button @click="$emit('close')">
          <img
            src="@/assets/svg/v2/close.svg"
            alt="Close cancel subscription"
          />
        </button>
      </div>

      <form>
        <h3>
          {{ $t('settings.subscriptions.cancel_informations') }}:
          {{ subscription.label }}
        </h3>
        <p>
          {{ $t('settings.subscriptions.cancel_access') }}:
          {{ lastSubscriptionTerm }}
        </p>
        <button class="generic-btn" @click.prevent="update()">
          {{ $t('settings.subscriptions.cancel_confirm') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import m from 'moment'

export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    lastSubscriptionTerm() {
      return m(this.subscription.commitment, 'YYYY-MM-DD HH:mm:ss').format(
        'DD/MM/YYYY'
      )
    },
  },
  methods: {
    ...mapActions({
      updateSubscriptions: 'updateSubscriptions',
    }),
    update() {
      const payload = {
        type: this.subscription.type,
        to: 0,
      }

      this.updateSubscriptions(payload)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

form {
  display: flex;
}

.cancel-subscription {
  z-index: 5;
  position: relative;

  .cancel-dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #444;
    border: 1px solid $border;
    width: 600px;
    border-radius: 20px;
    overflow: hidden;

    .cancel-dialog-content-header {
      display: flex;
      justify-content: space-between;
      padding: 23px;
      background: #444;
      border-radius: 20px 20px 0px 0px;
      border-bottom: 1px solid $border;

      button {
        background: transparent;
        border-radius: 76px;
        border: 1px solid $border;
        background: #444;
        width: 38px;
        height: 38px;

        img {
          margin-top: 4px;
        }
      }
    }

    form {
      flex-direction: column;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 20px;

      h3 {
        margin-top: 15px;
        color: white;
        font-size: 15px;
        font-weight: 500;
      }

      p {
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .cancel-dialog-content {
    top: 50% !important;
    width: 99% !important;
    max-width: 600px;

    .cancel-dialog-content-header {
      display: flex;
      align-items: center;
      padding: 23px 10px !important;
    }

    form {
      button {
        width: 200px;
      }
    }
  }
}
</style>
